.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #030a16;
    color: #d0e3e7;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.headerLogo {
    height: 80px;
    /* margin-left: 16px; */
}

.title {
    font-size: 26px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.Nav {
    display: flex;
    gap: 20px
}

.NavLink {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    transition: color 0.3s;

    &:hover {
        color: #1abc9c;
    }
}