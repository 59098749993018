a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

section {
    padding: 60px 0;
    min-height: 100vh;
}

.footer {
    padding: 100px 0px 0px;
    background-color: #030a16;
    transition: background-color 0.2s ease-in-out;
    color: rgba(255, 255, 255, .6);
}

.footer-light {
    background-color: #e3e4e6;
    color: rgba(0, 0, 0, 0.7);
}

.footer-light .widget1 p,
.footer-light .widget3 ul li a,
.footer-light .widget4 ul li a {
    color: rgba(0, 0, 0, 0.7);
    transition: color 0.2s ease-in-out;
}

.footer-light .widget4 ul li a:hover {
    color: #007bff;
}

.footer-light h5 {
    color: #000;
}

.footer-light .widget3 h5,
.footer-light .widget4 h5 {
    color: #000;
    transition: color 0.2s ease-in-out;
}

.footer-light .copyRightArea {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-light .copyRightArea p {
    color: rgba(0, 0, 0, 0.7);
    transition: color 0.2s ease-in-out;
}

.footer-light .copyRightArea {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.footer-light .copyRightArea p {
    color: rgba(0, 0, 0, 0.6);
}

.footer .widget1 p {
    font-size: 16px;
    color: rgba(255, 255, 255, .6);
    margin-top: 26px;
}

.footer .socialLinks {
    margin-top: 28px;
}

.socialLinks ul li {
    display: inline-block;
}


.socialLinks ul li a i {
    display: block;
    margin: 0 2px;
    width: 40px;
    height: 40px;
    background: #fafafa;
    border-radius: 50%;
    text-align: center;
    margin-right: 5px;
    line-height: 40px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.socialLinks ul li a .fa-facebook-f {
    color: #4267b2;
}

.socialLinks ul li a .fa-twitter {
    color: #1da1f2;
}

.socialLinks ul li a .fa-google-plus-g {
    color: #dd5044;
}

.socialLinks ul li a .fa-linkedin-in {
    color: #0177b5;
}


.socialLinks ul li a:hover i {
    color: #fff;
}

.socialLinks ul li a:hover .fa-facebook-f {
    background: #4267b2;
}

.socialLinks ul li a:hover .fa-twitter {
    background: #1da1f2;
}

.socialLinks ul li a:hover .fa-google-plus-g {
    background: #dd5044;
}

socialLinks ul li a:hover .fa-linkedin-in {
    background: #0177b5;
}

footer .logo {
    max-width: 300px;
}

.footer h5 {
    font-weight: 500;
    margin-bottom: 28px;
    text-transform: capitalize;
    font-weight: 600;
    color: #fff;
    font-size: 21px
}

.footer .widget2 .media img {
    margin-right: 20px;
    max-width: 100px;
}

.footer .widget2 .media p {
    font-size: 16px;
    color: rgba(255, 255, 255, .8);
    ;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-weight: 600;
    line-height: 26px;
}

.footer .widget2 .media span {
    font-size: 12px;
    color: #ffb606;
    text-transform: uppercase;
    margin-top: 15px;
    display: block;
}

.footer .widget2 .media {
    margin-bottom: 20px;
}

.footer .widget3 ul li a,
.footer .widget4 ul li a {
    font-size: 16px;
    color: rgba(255, 255, 255, .6);
    ;
    text-transform: capitalize;
    margin-bottom: 13px;
    display: block;
}

.footer .widget3 ul li a:hover,
.footer .widget4 ul li a:hover,
.footer .widget2 a:hover p {
    color: #ffb606;
}

.footer .widget3 h5 {
    margin-bottom: 22px;
    font-weight: 600;
    color: #fff;
    font-size: 21px;
    line-height: 32px;
}

.footer .widget4 h5 {
    margin-bottom: 22px;
    font-weight: 600;
    color: #fff;
    font-size: 21px;
}

.copyRightArea {
    margin-top: 83px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 25px 0px 28px;
}

.copyRightArea p {
    color: rgba(255, 255, 255, 0.6);
}