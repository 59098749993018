@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-light: #ffffff;
  --background-dark: #1a1a1a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-white dark:bg-gray-900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Enable dark mode transitions */
* {
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

/* Optional: add custom dark mode styles */
.dark body {
  background-color: var(--background-dark);
  color: #ffffff;
}

/* Optional: Add smooth scrolling */
html {
  scroll-behavior: smooth;
}